export const palette = {
  primary: { main: '#03124a' },
  secondary: { main: '#79e9fd', dark: '#067e7a', light: '#c9fb36' },
  danger: { main: '#D90368' },
  light: { main: '#F1E9DA' },
  dark: { main: '#0F0F0F' },
};
export const darkTheme = {
  palette: {
    primary: { main: '#03124a' },
    secondary: { main: '#79e9fd', dark: '#067e7a', light: '#c9fb36' },
    danger: { main: '#D90368' },
    light: { main: '#F1E9DA' },
    dark: { main: '#0F0F0F' },
    background: '#03124a',
    text: { first: '#fff', second: '#03124a' },
    bar: { first: '#79e9fd', second: '#0E3269' },
    arrow: 'transparent',
    footer: '../assets/images/bg/FooterDark',
  },
};
export const lightTheme = {
  palette: {
    primary: { main: '#03124a' },
    secondary: { main: '#79e9fd', dark: '#067e7a', light: '#c9fb36' },
    danger: { main: '#D90368' },
    light: { main: '#F1E9DA' },
    dark: { main: '#0F0F0F' },
    background: '#fff',
    text: { first: '#03124a', second: '#fff' },
    bar: { first: '#03124a', second: '#DFFCFF' },
    arrow: '#79e9fd',
    footer: '../assets/images/bg/FooterLight',
  },
};
