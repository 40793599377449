export const generalStyles = theme => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: 30,
      width: '100%',
    },
    update: {
      width: '100%',
      textAlign: 'right',
      color: theme.palette.text.first,
    },
    title: {
      color: theme.palette.text.first,
    },
    bar: {
      backgroundColor: theme.palette.bar.second,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      lineHeight: 1,
      padding: 25,
      paddingBottom: 40,
      paddingTop: 40,
    },
  };
};
