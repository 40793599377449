import React from 'react';
import { getStrings } from '../../resources/strings';
import { getImages } from '../../resources/images';
import { NavDropdown } from 'react-bootstrap';
import PrefContext from '../../context';
import { FB, Insta, Linkedin, LANGS2, ArrowDown, exchangeLinks, lendlingLinks, bitcoinLinks } from './data';
import { generalStyles } from './styles';
import configs from '../../config';

import Light from '../../assets/images/bg/FooterLight.svg';

class Footer extends React.Component {
  render() {
    const { language: lang, mode, theme, setLanguage } = this.context;
    const styles = generalStyles(theme, lang);
    const strings = getStrings(lang);
    const images = getImages(lang);

    return (
      <div style={{ ...styles.root, backgroundImage: mode == 'light' ? `url(${Light})` : null }}>
        <div style={styles.left_wrapper}>
          <img src={images.FooterLogo} style={styles.logo} />
          <div>
            <div style={styles.copyrights}>
              {strings.Copyrights} {new Date().getFullYear()} Bitsook {strings.RightsReserved}
            </div>
          </div>
        </div>
        <div style={styles.center_wrapper} className='cover-text-reg'>
          <div style={styles.links_wrapper}>
            <div style={styles.title}>{strings.Exchange_links}</div>
            {exchangeLinks.map(link => (
              <a style={styles.link} target='_blank' href={link.url}>
                <div style={styles.name}>{link.name}</div>
              </a>
            ))}
          </div>
          <div style={styles.links_wrapper}>
            <div style={styles.title}>{strings.Lending_platforms}</div>
            {lendlingLinks.map(link => (
              <a style={styles.link} target='_blank' href={link.url}>
                <div style={styles.name}>{link.name}</div>
              </a>
            ))}
          </div>
          <div style={styles.links_wrapper}>
            <div style={styles.title}>{strings.What_bitcoin}</div>
            {bitcoinLinks.map(link => (
              <a style={styles.link} target='_blank' href={link.url}>
                <div style={styles.name}>{link.name}</div>
              </a>
            ))}
          </div>
        </div>
        <div style={styles.right_wrapper.first}>
          <div style={styles.right_wrapper.second}>
            <div style={styles.icon_wrapper}>
              <FB style={styles.icon} onClick={() => window.open(configs.fb, '_blank')} />
            </div>
            <div style={styles.icon_wrapper}>
              <Linkedin style={styles.icon} onClick={() => window.open(configs.linkedin, '_blank')} />
            </div>
            <div style={styles.icon_wrapper}>
              <Insta style={styles.icon} onClick={() => window.open(configs.insta, '_blank')} />
            </div>
          </div>

          <div style={styles.right_wrapper.third}>
            <div style={styles.language_wrapper}>
              <div style={styles.language} className='cover-text-reg'>
                {strings.Language}
              </div>
            </div>
            <div style={styles.dropdown_wrapper}>
              <NavDropdown
                id='nav-dropdown'
                title={
                  <span style={styles.dropdown}>
                    {lang === 'EN' ? LANGS2[0] : LANGS2[1]}
                    <ArrowDown style={styles.arrow} />
                  </span>
                }
                style={styles.wrapper}
              >
                {LANGS2.map((lang, index) => {
                  return (
                    <NavDropdown.Item onClick={() => setLanguage(index === 0 ? 'EN' : 'AR')}>{lang}</NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Footer.contextType = PrefContext;
export default Footer;
