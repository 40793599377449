export const getStrings = lang => {
  return lang == 'EN'
    ? {
        GetCrypto: 'GET CRYPTOCURRENCY!',
        Home: 'Home',
        Explore: 'Explore',
        Prices: 'Prices',
        SignUp: 'Sign Up',
        'Sign In': 'Sign In',
        Wide_world: 'It’s a wide world to discover!',
        Buy_now: 'Buy Now',
        Arrow: '→',
        ArrowLeft: '←',
        ArrowRight: '→',
        Who_we_are: 'WHO WE ARE?',
        Discover_us: 'Discover us!',
        Why_us: 'WHY BITSOOK?',
        We_are_expanding: 'WE ARE EXPANDING THE WORLD OF VIRTUAL MONEY!',
        Exchange: 'EXCHANGE',
        Features: 'Features',
        BuyCrypto: 'Buy Cryptocurrency',
        Refill: 'Refill',
        ReadMore: 'Read More',
        CryptoCalculator: 'CRYPTOCURRENCY CALCULATOR',
        Pay: 'Amount to pay',
        Receive: 'Cryptocurrency to receive',
        Copyrights: 'Copyrights ©',
        RightsReserved: 'All Rights Reserved',
        Language: 'Language',
        Updated: 'Updated 3 seconds ago...',
        Exchange_now: 'Exchange',
        Exchange_links: 'Exchange Links:',
        Lending_platforms: 'Dex & Lending Platforms:',
        What_bitcoin: 'What is Bitcoin:',
        ExchangePar:
          'Business plan lean startup holy grail disruptive. Crowdfunding beta prototype gen-z incubator niche market.',
        DiscoverPar:
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',

        '': '',
      }
    : {
        GetCrypto: 'إكتشف العملات الرقمية',
        Home: 'الصفحة الرئيسية',
        Explore: 'اكتشف',
        Prices: 'الأسعار',
        SignUp: 'اشترك',
        'Sign In': 'تسجيل الدخول',
        Wide_world: 'إنه عالم كبير لكي تكتشفه!',
        Buy_now: 'اشتري الآن',
        Arrow: '←',
        ArrowLeft: '←',
        ArrowRight: '→',
        Who_we_are: 'من نحن ؟',
        Discover_us: 'اكتشفنا!',
        Why_us: 'لماذا بيتسووك ؟',
        We_are_expanding: 'نحن نقوم بتوسيع عالم العملات الرقمية!',
        Exchange: 'تبادل',
        Features: 'المميزات',
        BuyCrypto: 'اشتري عملة',
        Refill: 'ريفيل',
        ReadMore: 'اقرا المزيد',
        CryptoCalculator: 'حساب',
        Pay: 'ادفع',
        Receive: 'تلق',
        Copyrights: '  حقوق النشر ©',
        RightsReserved: 'جميع الحقوق محفوظة',
        Language: 'اللغة',
        Updated: 'محدث من ثلاث ثواني...',
        Exchange_now: 'تبادل',
        Exchange_links: 'روابط للتبادل : ',
        Lending_platforms: 'منصات اعارة : ',
        What_bitcoin: 'ماهو البيتكوين : ',
        ExchangePar:
          'Lorem Ipsum هو ببساطة نص وهمي لصناعة الطباعة والتنضيد. كان لوريم إيبسوم هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر ، عندما أخذت طابعة غير معروفة مجموعة من الأنواع وخلطتها لعمل كتاب نوع. لقد نجا ليس فقط خمسة قرون ، ولكن أيضًا قفزة في التنضيد الإلكتروني ، وبقي دون تغيير بشكل أساسي. تم تعميمه في الستينيات مع إصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.',
        DiscoverPar:
          'Lorem Ipsum هو ببساطة نص وهمي لصناعة الطباعة والتنضيد. كان لوريم إيبسوم هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر ، عندما أخذت طابعة غير معروفة مجموعة من الأنواع وخلطتها لعمل كتاب نوع. لقد نجا ليس فقط خمسة قرون ، ولكن أيضًا قفزة في التنضيد الإلكتروني ، وبقي دون تغيير بشكل أساسي. تم تعميمه في الستينيات مع إصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.',

        '': '',
      };
};
// export default {
//   EN: {
//     GetCrypto: 'GET CRYPTOCURRENCY!',
//     Home: 'Home',
//     Explore: 'Explore',
//     Prices: 'Prices',
//     SignUp: 'Sign Up',
//     'Sign In': 'Sign In',
//     Wide_world: 'It’s a wide world to discover!',
//     Buy_now: 'Buy Now',
//     Arrow: '→',
//     ArrowLeft: '←',
//     ArrowRight: '→',
//     Who_we_are: 'WHO WE ARE?',
//     Discover_us: 'Discover us!',
//     Why_us: 'WHY BITSOOK?',
//     We_are_expanding: 'WE ARE EXPANDING THE WORLD OF VIRTUAL MONEY!',
//     Exchange: 'EXCHANGE',
//     Features: 'Features',
//     BuyCrypto: 'Buy Cryptocurrency',
//     Refill: 'Refill',
//     ReadMore: 'Read More',
//     CryptoCalculator: 'CRYPTOCURRENCY CALCULATOR',
//     Pay: 'Amount to pay',
//     Receive: 'Cryptocurrency to receive',
//     Copyrights: 'Copyrights ©',
//     RightsReserved: 'All Rights Reserved',
//     Language: 'Language',
//     Updated: 'Updated 3 seconds ago...',
//     Exchange_now: 'Exchange',
//     ExchangePar:
//       'Business plan lean startup holy grail disruptive. Crowdfunding beta prototype gen-z incubator niche market.',
//     DiscoverPar:
//       'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',

//     '': '',
//   },
//   AR: {
//     GetCrypto: 'إكتشف العملات الرقمية',
//     Home: 'الصفحة الرئيسية',
//     Explore: 'اكتشف',
//     Prices: 'الأسعار',
//     SignUp: 'اشترك',
//     'Sign In': 'تسجيل الدخول',
//     Wide_world: 'إنه عالم كبير لكي تكتشفه!',
//     Buy_now: 'اشتري الآن',
//     Arrow: '←',
//     ArrowLeft: '←',
//     ArrowRight: '→',
//     Who_we_are: 'من نحن ؟',
//     Discover_us: 'اكتشفنا!',
//     Why_us: 'لماذا Bitsook ؟',
//     We_are_expanding: 'نحن نقوم بتوسيع عالم العملات الرقمية!',
//     Exchange: 'تبادل',
//     Features: 'المميزات',
//     BuyCrypto: 'اشتري عملة',
//     Refill: 'ريفيل',
//     ReadMore: 'اقرا المزيد',
//     CryptoCalculator: 'حساب',
//     Pay: 'ادفع',
//     Receive: 'تلق',
//     Copyrights: '  حقوق النشر ©',
//     RightsReserved: 'جميع الحقوق محفوظة',
//     Language: 'اللغة',
//     Updated: 'محدث من ثلاث ثواني...',
//     Exchange_now: 'تبادل',
//     ExchangePar:
//       'Lorem Ipsum هو ببساطة نص وهمي لصناعة الطباعة والتنضيد. كان لوريم إيبسوم هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر ، عندما أخذت طابعة غير معروفة مجموعة من الأنواع وخلطتها لعمل كتاب نوع. لقد نجا ليس فقط خمسة قرون ، ولكن أيضًا قفزة في التنضيد الإلكتروني ، وبقي دون تغيير بشكل أساسي. تم تعميمه في الستينيات مع إصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.',
//     DiscoverPar:
//       'Lorem Ipsum هو ببساطة نص وهمي لصناعة الطباعة والتنضيد. كان لوريم إيبسوم هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر ، عندما أخذت طابعة غير معروفة مجموعة من الأنواع وخلطتها لعمل كتاب نوع. لقد نجا ليس فقط خمسة قرون ، ولكن أيضًا قفزة في التنضيد الإلكتروني ، وبقي دون تغيير بشكل أساسي. تم تعميمه في الستينيات مع إصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.',

//     '': '',
//   },
// };
