import React, { useState, useEffect, useContext } from 'react';
import Header from '../components/Page/header';
import { exchange } from '../API/api.js';
import PrefContext from '../context';
import { getStrings, getImages } from '../resources';
import { Page, CoverText, Prices } from '../components';
import { IFrameModal, Exchange, Paragraph, Calculator, Slider, Cards } from './';
import { generalStyles } from './styles';

const MAX = 20;
const LENGTH = 5;

const Main = () => {
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [status, setStatus] = useState(false); //0 exchange 1 loading 2 buy
  const [position, setPosition] = useState(0);
  const [currentCard, setCurrentCard] = useState({});
  const [pay, setPay] = useState(0);
  const [receive, setReceive] = useState(null);
  const [width, setWidth] = useState(0);

  const AppContext = useContext(PrefContext);

  useEffect(() => {
    reportWindowSize();
    window.addEventListener('resize', reportWindowSize);
    return function cleanup() {
      window.removeEventListener('resize', reportWindowSize);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPosition(position == LENGTH - 1 ? 0 : position + 1);
    }, 3000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });
  function reportWindowSize() {
    setWidth(window.innerWidth);
  }
  async function exchangeAmount() {
    if (pay > 0) {
      setStatus(1);
      const [status, receive] = await exchange();
      if (status) {
        setReceive(pay / receive);
        setStatus(2);
      } else {
        setReceive(0);
        setStatus(2);
      }
    }
  }
  function getAmount(e) {
    if (e.target.value.length > MAX) e.target.value = e.target.value.slice(0, MAX);
    if (isNaN(e.target.value)) e.target.value = pay;
    setPay(e.target.value);
    setStatus(0);
  }
  function hideSpinner() {
    setLoading(false);
  }

  function openModal(card) {
    setLoading(true);
    setCurrentCard(card);
    setModalOpened(true);
  }
  function closeModal() {
    setModalOpened(false);
  }

  const { language: lang, mode, theme } = AppContext;
  const styles = generalStyles(theme, lang, mode, width);
  const strings = getStrings(lang);
  const images = getImages(lang);
  return (
    <>
      <Page>
        <div style={styles.root}>
          <Header logoLoaded={logoLoaded} setLogoLoaded={setLogoLoaded} setImageLoaded={setImageLoaded} />
          <div className='cover' style={styles.row_wrapper}>
            <div className='row' style={styles.row}>
              <CoverText
                text={strings.GetCrypto}
                subtext={strings.Wide_world}
                link={{ label: strings.Buy_now, href: '#calculator' }}
              />
              <Slider
                width={width}
                styles={styles}
                images={images}
                position={position}
                openModal={openModal}
                imageLoaded={imageLoaded}
                setImageLoaded={setImageLoaded}
              />
            </div>
          </div>
        </div>
        <Cards strings={strings} styles={styles} openModal={openModal} />
        <Paragraph strings={strings} styles={styles} images={images} />
        <div style={styles.bar.first} className='cover-text-col'>
          <p style={styles.bar_text.first} className='cover-text-semibold'>
            {strings.We_are_expanding}
          </p>
        </div>
        <div className='why-bitsook' style={styles.exchange_background}>
          <Exchange strings={strings} styles={styles} />
          <Calculator
            status={status}
            exchangeAmount={exchangeAmount}
            getAmount={getAmount}
            pay={pay}
            receive={receive}
            strings={strings}
            styles={styles}
            images={images}
          />
          <Prices />
        </div>
      </Page>
      <IFrameModal
        modalOpened={modalOpened}
        closeModal={closeModal}
        hideSpinner={hideSpinner}
        currentCard={currentCard}
        loading={loading}
      />
    </>
  );
};

export default Main;
