import { palette } from '../../resources/theme';
export const generalStyles = (theme, lang) => {
  return {
    root: {
      width: '55%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      width: '65%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    text: {
      lineHeight: 1,
      fontWeight: 200,
      fontSize: '7vmin',
      color: theme.palette.text.first,
      fontFamily: 'Work Sans Regular',
    },
    subtext: {
      opacity: 0.6,
      fontSize: '3.5vmin',
      textAlign: 'left',
      color: theme.palette.text.first,
      fontFamily: 'Work Sans Regular',
    },
    wrapper: {
      width: 150,
      height: 40,
      borderRadius: 39.5,
      backgroundColor: palette.secondary.main,
      boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 35,
      marginBottom: 60,
      cursor: 'pointer',
    },
    link: {
      fontSize: 13,
      color: palette.primary.main,
    },
    href: {
      textDecoration: 'none',
    },
    arrow: {
      fontWeight: 'bold',
      fontSize: 20,
      color: palette.primary.main,
      marginLeft: lang === 'EN' ? 15 : 0,
      marginRight: lang === 'EN' ? 0 : 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: { width: 15, height: 15 },
  };
};
