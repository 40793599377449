import React from 'react';
import Spinner from 'react-spinkit';
import Modal from 'react-modal';
const modalStyle = {
  overlay: {
    backgroundColor: 'transparent',
    zIndex: 100,
  },
  content: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    borderRadius: 5,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#29333D',
    width: '80%',
    overflow: 'scroll',
  },
};
const spinnerStyle = {
  position: 'absolute',
};
export const IFrameModal = ({ loading, modalOpened, closeModal, hideSpinner, currentCard }) => {
  return (
    <Modal isOpen={modalOpened} onRequestClose={closeModal} style={modalStyle}>
      {loading ? (
        <div style={spinnerStyle}>
          <Spinner name='three-bounce' color='#fff' fadeIn='none' />
        </div>
      ) : null}
      <div className='iframe-holder'>
        <iframe src={currentCard.url} onLoad={hideSpinner} scrolling='yes' />
      </div>
    </Modal>
  );
};
