import React from 'react';
import Spinner from 'react-spinkit';
import { rightStyle, buttonStyle } from './styles';

const phones = [
  { title: 'Amazon', url: 'https://embed.bitrefill.com/buy/amazon_ca-canada' },
  { title: 'Netflix', url: 'https://embed.bitrefill.com/buy/netflix-usa' },
  { title: 'Playstation', url: 'https://embed.bitrefill.com/buy/psn-lebanon' },
  { title: 'Skype', url: 'https://embed.bitrefill.com/buy/skype-usd-international' },
  { title: 'Alfa', url: 'https://embed.bitrefill.com/buy/alfa-lebanon' },
];

export const Slider = ({ styles, images, openModal, imageLoaded, setImageLoaded, width, position }) => {
  return (
    <div style={rightStyle(width)}>
      <div style={styles.image_wrapper}>
        <img
          style={styles.image}
          src={images[phones[position].title]}
          className={`image-${imageLoaded ? 'visible' : 'hidden'}`}
          onLoad={() => {
            setTimeout(() => {
              setImageLoaded(true);
            }, 500);
          }}
        />
        <div style={buttonStyle(width)} onClick={() => openModal(phones[position])} />
      </div>
      <div style={{ ...styles.image_wrapper, display: !imageLoaded ? 'flex' : 'none' }}>
        <Spinner name='wandering-cubes' color='#fff' fadeIn='none' />
      </div>
    </div>
  );
};
