import React from 'react';
import PrefContext from '../../context';
import Header from './header';
import Footer from './footer';

class Page extends React.Component {
  render() {
    const theme = this.context.theme;

    return (
      <div style={{ backgroundColor: theme.palette.background }}>
        {/* <Header /> */}
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
Page.contextType = PrefContext;
export default Page;
