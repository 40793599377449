import React from 'react';
import Context from '../../context';
import Spinner from 'react-spinkit';
import { getStrings } from '../../resources/strings';
import { getImages } from '../../resources/images';
import { Nav, Navbar } from 'react-bootstrap';
import { generalStyles } from './styles';
import { FB, Insta, Linkedin, LANGS, ArrowDown } from './data';
import configs from '../../config';
import './styles.css';

class Header extends React.Component {
  state = { light: false, navExpanded: false };

  componentDidMount = () => {
    const { mode } = this.context;
    this.setState({ light: mode == 'light' ? true : false });
    document.addEventListener('mousedown', this.handleDocumentClick);
  };
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentClick);
  }
  toggle = setTheme => {
    this.setState({ light: !this.state.light });
    setTheme(this.state.light ? 'dark' : 'light');
  };
  setNavExpanded = expanded => {
    this.setState({ navExpanded: expanded });
  };
  closeNav = () => {
    this.setState({ navExpanded: false });
  };
  handleDocumentClick = e => {
    const container = this._element;
    if (e.target !== container && !container.contains(e.target)) this.closeNav();
  };
  render() {
    const { language: lang, theme, setLanguage, setTheme } = this.context;
    const { logoLoaded, setLogoLoaded, setImageLoaded } = this.props;
    const styles = generalStyles(theme, lang);
    const strings = getStrings(lang);
    const images = getImages(lang);

    return (
      <div className='container header'>
        <Navbar
          expand='lg'
          expanded={this.state.navExpanded}
          onToggle={this.setNavExpanded}
          style={styles.header_container}
        >
          <Navbar.Brand href='#home' style={styles.logo}>
            <Spinner
              name='wandering-cubes'
              color='#fff'
              fadeIn='none'
              style={{ display: logoLoaded ? 'none' : 'inline', marginRight: lang == 'EN' ? 0 : 50 }}
            />
            <img
              className={`image-${logoLoaded ? 'visible' : 'hidden'}`}
              src={images.Logo}
              onLoad={() => {
                setTimeout(() => {
                  setLogoLoaded(true);
                }, 500);
              }}
            />
          </Navbar.Brand>

          <div ref={c => (this._element = c)}>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav onSelect={this.closeNav}>
                <Nav.Link href='#' className='nounderline link nav-item' style={styles.text_wrapper}>
                  {strings.Home}
                </Nav.Link>
                <Nav.Link href='#calculator' className='nounderline link nav-item' style={styles.text_wrapper}>
                  {strings.BuyCrypto}
                </Nav.Link>
                <Nav.Link href='#refill' className='nounderline link nav-item' style={styles.text_wrapper}>
                  {strings.Refill}
                </Nav.Link>
                <Nav.Link
                  href={configs.insta}
                  target='_blank'
                  className='nounderline link nav-item'
                  style={styles.icon_wrapper}
                >
                  <Insta style={styles.icon} />
                </Nav.Link>
                <Nav.Link
                  href={configs.linkedin}
                  target='_blank'
                  className='nounderline link nav-item'
                  style={styles.icon_wrapper}
                >
                  <Linkedin style={styles.icon} />
                </Nav.Link>
                <Nav.Link
                  href={configs.fb}
                  target='_blank'
                  className='nounderline link nav-item'
                  style={styles.icon_wrapper}
                >
                  <FB style={styles.icon} />
                </Nav.Link>
                <Nav.Link
                  className='nounderline link nav-item'
                  style={styles.text_wrapper}
                  onClick={() => {
                    setLanguage(lang == 'EN' ? 'AR' : 'EN');
                    this.closeNav();
                    setImageLoaded(false);
                    setLogoLoaded(false);
                  }}
                >
                  {lang == 'EN' ? 'AR' : 'EN'}
                </Nav.Link>
                <div style={styles.toggle_wrapper}>
                  <label
                    class='switch'
                    onChange={() => {
                      this.toggle(setTheme);
                      this.closeNav();
                    }}
                  >
                    <input type='checkbox' checked={this.state.light} />
                    <span class='slider round'></span>
                  </label>
                </div>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}

Header.contextType = Context;

export default Header;
