import React from 'react';
import { HashRouter, Switch, Route, BrowserRouter } from 'react-router-dom';
import { Main } from './pages';
import PrefContext from './context';
import { darkTheme, lightTheme } from './resources/theme';
import Spinner from 'react-spinkit';

class App extends React.Component {
  state = { language: 'EN', mode: 'light', loading: true };

  componentDidMount() {
    let lang = 'EN';
    let mode = 'light';

    let CurrentLan = localStorage.getItem('lang');
    let currentMode = localStorage.getItem('mode');

    if (CurrentLan == null) localStorage.setItem('lang', lang);
    else lang = localStorage.getItem('lang');

    if (currentMode == null) localStorage.setItem('mode', mode);
    else mode = localStorage.getItem('mode');

    this.setState({ language: lang, mode, loading: false });
    window.document.documentElement.lang = lang.toLowerCase();
  }

  setLanguage = language => {
    this.setState({ language });
    localStorage.setItem('lang', language);
    window.document.documentElement.lang = language.toLowerCase();
  };
  setTheme = mode => {
    this.setState({ mode });
    localStorage.setItem('mode', mode);
  };
  render() {
    const { language, mode, loading } = this.state;
    const dir = language === 'AR' ? 'rtl' : 'ltr';
    if (loading)
      return (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner name='bounce' color='#03124a' fadeIn='none' />
        </div>
      );

    return (
      <div className='w-100 bg-background' dir={dir}>
        <PrefContext.Provider
          value={{
            theme: mode === 'dark' ? darkTheme : lightTheme,
            mode,
            language,
            setLanguage: this.setLanguage,
            setTheme: this.setTheme,
          }}
        >
          <React.Fragment>
            <BrowserRouter>
              <Switch>
                <Route path='/' component={Main} />
              </Switch>
            </BrowserRouter>
          </React.Fragment>
        </PrefContext.Provider>
      </div>
    );
  }
}

export default App;
