import React from 'react';
import Spinner from 'react-spinkit';
import { DoubleArrows } from './data';
import Configs from '../config';

const MAX = 20;

export const Calculator = ({ status, exchangeAmount, getAmount, pay, receive, strings, styles, images }) => {
  return (
    <div style={styles.bar.second} className='cover-text-col' id='calculator'>
      <div style={styles.bar_text.second}> {strings.CryptoCalculator}</div>
      <div style={styles.cal_wrapper}>
        <div style={styles.input_wrapper.first}>
          <div style={styles.input_wrapper.second}>
            <div style={styles.pay_text}>{strings.Pay}</div>
            <input style={styles.input} onChange={getAmount} inputMode='decimal' />
          </div>
          <span style={{ ...styles.currency, ...styles.currency_dir }}>USD</span>
        </div>
        <div style={styles.double_arrow_wrapper}>
          <DoubleArrows style={styles.arrow_icon} />
        </div>
        <div style={styles.input_wrapper.first}>
          <div style={styles.input_wrapper.second}>
            <div style={styles.pay_text}>{strings.Receive}</div>
            <input style={styles.output} disabled value={receive?.toString().substr(0, MAX)} />
          </div>
          <span style={{ ...styles.currency, ...styles.currency_dir }}>BTC</span>
        </div>
        {status == 0 ? (
          <a style={styles.href}>
            <div style={styles.button.third} onClick={exchangeAmount}>
              <span style={styles.buy} className='cover-text-semibold'>
                {strings.Exchange_now}
              </span>
              <span style={styles.arrow}>
                <img src={images.Arrow} style={styles.icon} />
              </span>
            </div>
          </a>
        ) : status === 1 ? (
          <a style={styles.href}>
            <div style={styles.button.third}>
              <Spinner name='circle' color='#03124a' fadeIn='none' />
            </div>
          </a>
        ) : (
          <a
            href={`https://api.whatsapp.com/send?phone=${Configs.phone}&text=hi, I would like to buy from Bitsook: USD: ${pay}, BTC:${receive}`}
            target='_blank'
            style={styles.href}
          >
            <div style={styles.button.third}>
              <span style={styles.buy} className='cover-text-semibold'>
                {strings.Buy_now}
              </span>
              <span style={styles.arrow}>
                <img src={images.Arrow} style={styles.icon} />
              </span>
            </div>
          </a>
        )}
      </div>
    </div>
  );
};
