import React from 'react';

const cards = [
  { title: 'Amazon', url: 'https://embed.bitrefill.com/buy/amazon_ca-canada', img: 'amazon' },
  { title: 'Netflix', url: 'https://embed.bitrefill.com/buy/netflix-usa', img: 'netflix' },
  { title: 'Playstation', url: 'https://embed.bitrefill.com/buy/psn-lebanon', img: 'playstation' },
  { title: 'Touch', url: 'https://embed.bitrefill.com/buy/touch-mobile-lebanon/?hl=en', img: 'touch' },
  { title: 'Viber', url: 'https://embed.bitrefill.com/buy/viber-usd/?hl=en', img: 'viber' },
];
export const Cards = ({ strings, styles, openModal }) => {
  return (
    <div style={styles.card_wrapper} id='refill'>
      {cards.map((card, index) => {
        return (
          <div style={styles.card} onClick={() => openModal(cards[index])}>
            <img
              style={{ width: card.img == 'touch' || card.img == 'viber' ? 135 : 160 }}
              src={require(`../assets/images/cards/${card.img}.svg`)}
            />
            <div style={styles.card_text}>{strings.Buy_now}</div>
          </div>
        );
      })}
    </div>
  );
};
