import React from 'react';

export const Paragraph = ({ strings, styles, images }) => {
  return (
    <div className='container who-we-are' style={styles.title}>
      <h1 style={styles.p.first}>{strings.Who_we_are}</h1>
      <p style={styles.text_color}>{strings.Discover_us}</p>
      <p className='mx-auto cover-text-reg' style={styles.p.second}>
        {strings.DiscoverPar}
      </p>
      <div style={styles.button.second}>
        <span style={styles.link} className='cover-text-semibold'>
          {strings.ReadMore}
        </span>
        <span style={styles.arrow}>
          <img src={images.Arrow} style={styles.icon} />
        </span>
      </div>
    </div>
  );
};
