export const getImages = lang => {
  return lang == 'EN'
    ? {
        Logo: require('../assets/images/logo.svg'),
        FooterLogo: require('../assets/images/footer-logo.svg'),
        Arrow: require('../assets/images/svg/right-arrow.svg'),
        Amazon: require('../assets/images/phones/en/amazon.png'),
        Netflix: require('../assets/images/phones/en/netflix.png'),
        Playstation: require('../assets/images/phones/en/playstation.png'),
        Skype: require('../assets/images/phones/en/skype.png'),
        Alfa: require('../assets/images/phones/en/alfa.png'),
      }
    : {
        Logo: require('../assets/images/logo-ar.svg'),
        FooterLogo: require('../assets/images/footer-logo-ar.svg'),
        Arrow: require('../assets/images/svg/left-arrow.svg'),
        Amazon: require('../assets/images/phones/ar/amazon.png'),
        Netflix: require('../assets/images/phones/ar/netflix.png'),
        Playstation: require('../assets/images/phones/ar/playstation.png'),
        Skype: require('../assets/images/phones/ar/skype.png'),
        Alfa: require('../assets/images/phones/ar/alfa.png'),
      };
};
