import { palette } from '../resources/theme';
import Light from '../assets/images/bg/HeaderLight.svg';

export const generalStyles = (theme, lang, mode, width) => {
  return {
    root: { backgroundImage: mode == 'light' ? `url(${Light})` : null, backgroundSize: 'cover' },
    row_wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 20,
      width: '100%',
    },

    image_wrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '70%',
      // overflow: 'hidden',
    },
    button: {
      first: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: '#fff',
        // boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer',
        position: 'absolute',
        bottom: '5vmin',
        right: 5,
        left: 5,
        fontFamily: 'Work Sans',
        textAlign: 'center',
        marginLeft: '26%',
        marginRight: '27%',
        marginBottom: '2.5%',
        height: '15%',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'transparent',
      },
      second: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 150,
        height: 40,
        borderRadius: 39.5,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer',
        marginTop: 35,
      },
      third: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 150,
        height: 40,
        borderRadius: 39.5,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
        cursor: 'pointer',
        marginTop: 15,
      },
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 100,
    },
    text_color: {
      color: theme.palette.text.first,
      textAlign: 'center',
    },

    p_color: {
      color: theme.palette.text.first,
    },
    exchange_background: {
      backgroundColor: theme.palette.text.second,
    },
    p: {
      first: {
        lineHeight: 0.7,
        fontFamily: 'Work Sans Regular',
        fontSize: 40,
        color: theme.palette.text.first,
        textAlign: 'center',
      },
      second: {
        width: '85%',
        marginTop: 30,
        color: theme.palette.text.first,
        textAlign: 'center',
      },
    },
    link: {
      fontSize: 13,
      color: theme.palette.primary.main,
    },
    arrow: {
      fontWeight: 'bold',
      fontSize: 20,
      color: palette.primary.main,
      marginLeft: lang === 'EN' ? 15 : 0,
      marginRight: lang === 'EN' ? 0 : 15,
      fontFamily: 'Work Sans',
    },
    bar: {
      first: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.bar.first,
        marginTop: 150,
        lineHeight: 1,
        padding: 25,
        paddingBottom: 5,
      },
      second: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.bar.first,
        marginTop: 150,
        lineHeight: 1,
        padding: 25,
        paddingBottom: 60,
        paddingTop: 60,
      },
    },
    bar_text: {
      first: {
        fontSize: '7vmin',
        color: theme.palette.text.second,
        width: '70%',
        textAlign: 'center',
        letterSpacing: 1,
      },
      second: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Work Sans Regular',
        fontSize: '6vmin',
        fontStyle: 'normal',
        textAlign: 'center',
        color: '#fff',
        width: '70%',
        marginBottom: 60,
      },
    },
    grid: { width: '85%', marginRight: 'auto', marginLeft: 'auto', marginTop: 'auto' },
    exchange: {
      first: {
        marginLeft: 50,
        marginRight: 50,
        marginTop: 50,
      },
      second: { marginLeft: 50, marginRight: 50 },
    },
    img: { width: 120 },
    text: { fontSize: 20, color: theme.palette.text.first },
    cal_wrapper: {
      display: 'flex',
      flexDirection: width < 820 ? 'column' : 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      flexWrap: 'wrap',
    },
    input_wrapper: {
      first: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        marginRight: 50,
        marginLeft: 50,
        marginBottom: 10,
      },
      second: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        position: 'relative',
      },
    },
    pay_text: { textAlign: 'right', color: '#fff', marginBottom: 10 },
    input: { width: 260, height: 38, borderRadius: 4, position: 'relative', color: theme.palette.primary.main },
    output: { width: 260, height: 38, borderRadius: 4, position: 'relative', color: theme.palette.text.second },
    currency: {
      position: 'absolute',
      fontFamily: 'Work Sans Regular',
      fontSize: 20,
      fontWeight: '600',
      fontStyle: 'normal',
      textAlign: 'right',
      color: theme.palette.primary.main,
      marginRight: 5,
      marginLeft: 5,
      bottom: 10,
    },
    currency_dir: lang == 'EN' ? { right: 0 } : { left: 0 },
    double_arrow_wrapper: {
      width: 50,
      height: 50,
      borderRadius: 25,
      backgroundColor: theme.palette.arrow,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 30,
      marginBottom: 30,
    },
    arrow_icon: { width: 40 },
    buy: {
      fontSize: 13,
      color: theme.palette.primary.main,
    },
    card_wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      paddingTop: 50,
      paddingBottom: 40,
    },
    card: {
      width: 180,
      height: 180,
      cursor: 'pointer',
      margin: 20,
      borderRadius: 180 / 2,
      backgroundImage: 'linear-gradient(to bottom,#051556,#092A72)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    card_text: { color: 'white', bottom: 25, position: 'absolute' },
    img_wrapper: {
      cursor: 'pointer',
    },
    spinner: {
      position: 'absolute',
    },
    iframe: {
      width: '100%',
      height: '100%',
      zIndex: 99999,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      border: 'none',
      margin: 5,
    },
    href: {
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: 13,
      marginRight: 15,
      fontFamily: 'Work Sans',
      color: theme.palette.primary.main,
    },
    icon: { width: 15, height: 15 },
  };
};
export const rightStyle = width => {
  return {
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowRadius: 60,
    shadowOpacity: 1,
    backgroundColor: palette.secondary.main,
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: width > 500 ? 450 : 300,
    height: width > 500 ? 450 : 300,
    borderRadius: width > 500 ? 450 / 2 : 300 / 2,
    marginBottom: 50,
  };
};
export const buttonStyle = width => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'absolute',
    bottom: '5vmin',
    right: 5,
    left: 5,
    fontFamily: 'Work Sans',
    textAlign: 'center',
    marginLeft: '26%',
    marginRight: '27%',
    marginBottom: '2.5%',
    height: '15%',
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'transparent',
    borderBottomLeftRadius: width > 500 ? 25 : 15,
    borderBottomRightRadius: width > 500 ? 25 : 15,
  };
};
export const modalStyle = {
  overlay: {
    backgroundColor: 'transparent',
    zIndex: 100,
  },
  content: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    borderRadius: 5,
    border: 'none',
    // padding: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#29333D',
    width: '80%',
    overflow: 'scroll',
  },
};
