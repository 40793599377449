import axios from 'axios';
import config from '../config.js';
//'BTC, 'XRP',  'XLM' , 'ETH','LTC;
//'TRX','XEM'
const coins = ['BTC', 'LTC', 'ETH'];
const instance = axios.create({
  //   baseURL: 'https://jsonplaceholder.typicode.com/',
  //   withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});
export const getLatest = async () => {
  try {
    const promises = coins.map(
      c =>
        new Promise(async resolve => {
          const { data } = await instance.get(config.backend + `prices/${c}-USD/spot`);
          resolve(data.data);
        })
    );
    const data = await Promise.all(promises);
    console.log(data);
    return data;
  } catch (err) {
    return [];
  }
};
export const exchange = async () => {
  try {
    const { data } = await instance.get(`https://api.coinbase.com/v2/prices/BTC-USD/spot`);
    if (data?.data) return [true, data.data.amount];
    else return [false, null];
  } catch (err) {
    return [];
  }
};
