export { ReactComponent as FB } from '../../assets/images/social-icons/facebook.svg';
export { ReactComponent as Twitter } from '../../assets/images/social-icons/twitter.svg';
export { ReactComponent as Insta } from '../../assets/images/social-icons/instagram.svg';
export { ReactComponent as Linkedin } from '../../assets/images/social-icons/linkedin.svg';
export { ReactComponent as ArrowDown } from '../../assets/images/svg/ArrowDown.svg';
export const LANGS = ['EN', 'AR'];
export const LANGS2 = ['U.S. English', 'KSA Arabic'];

export const exchangeLinks = [
  { name: 'Binance', url: 'https://www.binance.com' },
  { name: 'Rain', url: 'https://www.rain.bh/' },
  { name: 'Bittrex', url: 'https://global.bittrex.com/' },
  { name: 'CoinBase', url: 'https://www.coinbase.com/' },
  { name: 'Gemini', url: 'https://gemini.com/' },
  { name: 'Bitoasis', url: 'https://bitoasis.net/en/home' },
];

export const lendlingLinks = [
  { name: 'UniSwap', url: 'https://app.uniswap.org/#/swap' },
  { name: 'SushiSwap', url: 'https://sushiswap.org/' },
  { name: 'Cream Finance', url: 'http://cream.finance/' },
  { name: 'Balanscer Exchange', url: 'https://balanscer.exchange/#/swap' },
  { name: 'Yearn Finance', url: 'https://yearn.finance/' },
  { name: 'Curve', url: 'https://www.curve.fi/' },
];
export const bitcoinLinks = [
  { name: 'Bitcoin', url: 'https://bitcoin.org/en/' },
  { name: 'Aantonop', url: 'https://www.youtube.com/user/aantonop' },
  { name: 'Bitcoin du Liban ', url: 'https://bitcoinduliban.org/' },
];
