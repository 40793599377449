import React from 'react';
import { EXCHANGE } from './data';

export const Exchange = ({ strings, styles }) => {
  return (
    <>
      <h1 style={styles.p.first}>{strings.Why_us}</h1>
      <p style={styles.text_color}>{strings.Discover_us}</p>
      <div className='grid' style={styles.grid}>
        {EXCHANGE.map((ex, index) => {
          return (
            <>
              <div
                className='d-flex flex-row align-items-center justify-content-start grid-row'
                style={styles.exchange.first}
              >
                <img src={require('../assets/images/grid-item.svg')} style={styles.img} />
                <div className='text-light m-5'>
                  <h3 style={styles.text}>{strings[ex.title]}</h3>
                  <p style={styles.p_color}>{strings[ex.par]}</p>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
