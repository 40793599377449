import React from 'react';
import PrefContext from '../../context';
import { generalStyles } from './styles';
import { getImages } from '../../resources/images';
class CoverText extends React.Component {
  render() {
    const { text, subtext, link } = this.props;
    const { theme, language: lang } = this.context;
    const styles = generalStyles(theme, lang);
    const images = getImages(lang);

    return (
      <div style={styles.root}>
        <div className='cover-text-col' style={styles.container}>
          <h1 style={styles.text}>
            {text}
            <br />
            <span style={styles.subtext}>{subtext}</span>
          </h1>
          <a href={link.href} style={styles.href}>
            <div style={styles.wrapper}>
              <span style={styles.link} className='cover-text-semibold'>
                {link.label}
              </span>
              <span style={styles.arrow} className='cover-text-semibold'>
                <img src={images.Arrow} style={styles.icon} />
              </span>
            </div>
          </a>
        </div>
      </div>
    );
  }
}
CoverText.contextType = PrefContext;
export default CoverText;
