export const generalStyles = (theme, lang) => {
  return {
    text_wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 18,
      fontFamily: 'Work Sans Regular',
      fontWeight: '200',
      fontStyle: 'normal',
      margin: 25,
      color: theme.palette.text.first,
    },
    icon_wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 20,
    },
    toggle_wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 10,
      marginLeft: 10,
      marginRight: 10,
    },
    icon: { width: 18, height: 18, fill: theme.palette.text.first, cursor: 'pointer' },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: 15,
      fontFamily: 'Work Sans Regular',
      fontWeight: '200',
      fontStyle: 'normal',
      color: 'red',
    },
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      paddingBottom: 30,
      flexWrap: 'wrap',
      backgroundSize: 'cover',
    },
    header_container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    left_wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 30,
    },
    right_wrapper: {
      first: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
      },
      second: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
      third: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    copyrights: {
      fontFamily: 'Work Sans Regular',
      fontSize: 12,
      fontWeight: 'normal',
      fontStyle: 'normal',
      marginTop: 20,
      color: theme.palette.text.first,
      textAlign: 'center',
    },
    language: {
      opacity: 0.5,
      fontSize: 14,
      textAlign: 'right',
      color: '#fff',
      color: theme.palette.text.first,
      // paddingRight: 50,
    },
    lang: {
      fontFamily: 'Work Sans',
      fontSize: 14,
      fontWeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'right',
      marginRight: 5,
      marginLeft: 5,
      color: theme.palette.text.first,
    },
    dropdown: { color: theme.palette.text.first },
    arrow: { stroke: theme.palette.text.first, margin: 10 },
    language_wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      width: '50%',
    },
    dropdown_wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      width: '50%',
      marginLeft: lang == 'EN' ? 25 : 0,
      marginRight: lang == 'EN' ? 0 : 45,
    },
    dropdown: { color: theme.palette.text.first },
    lang: { marginLeft: 10, marginRight: 5 },
    collapse: {
      backgroundColor: theme.palette.text.second,
    },
    logo: {
      width: '250px',
      height: '70px',
      marginLeft: lang == 'EN' ? 15 : 0,
    },
    link: {
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    links_wrapper: {
      display: 'flex',
      flexDirection: 'column',
      margin: 5,
      marginRight: 30,
      marginLeft: 30,
    },
    center_wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      textAlign: lang == 'EN' ? 'left' : 'right',
    },
    title: { color: theme.palette.text.first, fontSize: 17, marginBottom: 5 },
    name: { color: theme.palette.text.first, fontSize: 12, margin: 2 },
  };
};
