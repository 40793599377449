import React, { Suspense } from 'react';
import { getStrings } from '../../resources/strings';
import { getLatest } from '../../API/api.js';
import Context from '../../context';
import { generalStyles } from './styles';
const BTC = require('../../assets/images/currencies/BTC.svg');
const XRP = require('../../assets/images/currencies/XRP.svg');
const TRX = require('../../assets/images/currencies/TRX.svg');
const XLM = require('../../assets/images/currencies/XLM.svg');
const XEM = require('../../assets/images/currencies/XEM.svg');
const ETH = require('../../assets/images/currencies/ETH.svg');
const LTC = require('../../assets/images/currencies/LTC.svg');

const icons = {
  BTC: { icon: BTC, name: 'Bitcoin' },
  //   XRP: { icon: XRP, name: 'Ripple' },
  //   TRX: { icon: TRX, name: 'Tron' },
  //   XLM: { icon: XLM, name: 'Stellar' },
  //   XEM: { icon: XEM, name: 'Nem' },
  ETH: { icon: ETH, name: 'Ethereum' },
  LTC: { icon: LTC, name: 'Litecoin' },
};

function commafy(num) {
  var str = num.toString().split('.');
  if (str[0].length >= 5) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
}

const PriceRow = ({ data, styles }) => {
  return (
    <div className='d-flex flex-row align-items-center justify-content-start price-item'>
      <img className='currency' src={icons[data.base].icon} />
      <div className='d-flex flex-column align-items-start justify-content-start'>
        <div style={styles.title}>
          {icons[data.base].name} ({data.base})
        </div>
        <div className='text-success' style={{ marginTop: 10 }}>
          {commafy(parseFloat(data.amount).toFixed(3))} {data.currency}
        </div>
      </div>
    </div>
  );
};

class Prices extends React.Component {
  state = {
    data: [
      {
        base: 'BTC',
        amount: '0',
        currency: 'LBP',
      },
      {
        base: 'LTC',
        amount: '0',
        currency: 'LBP',
      },
      {
        base: 'ETH',
        amount: '0',
        currency: 'LBP',
      },
    ],
  };
  componentDidMount() {
    this.getPrices();
    this.interval = setInterval(this.getPrices, 60000);
  }
  getPrices = async () => {
    const data = await getLatest();
    this.setState({ data });
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { data } = this.state;
    const { language: lang, theme } = this.context;
    const styles = generalStyles(theme);
    const strings = getStrings(lang);

    return (
      <div style={styles.bar}>
        <Suspense fallback={<div className='text-light'>loading...</div>}>
          <div style={styles.update}>{strings.Updated}</div>
          <div style={styles.wrapper}>
            {data.map((d, index) => (
              <Suspense fallback={<PriceRow data={d} />} key={index}>
                <PriceRow data={d} styles={styles} />
              </Suspense>
            ))}
          </div>
        </Suspense>
      </div>
    );
  }
}
Prices.contextType = Context;
export default Prices;
